.blog { text-align: left; }

.content {
  max-width: 50%;
  margin: 40px auto;
  text-align: left;
}

.date { font-size: 15px; }

.blog-title { font-size: 28px; }


.blogs { opacity: .6; }

.blogs:hover { opacity: 1; }

.logo {
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .logo img {
    height: 40px;
    width: 60px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .logo img {
    height: 35px;
    width: 50px;
  }
}

.blog-link { 
  opacity: .6;
  color: black; 
}

.blog-link:hover { 
  opacity: 1;
  color: black 
}

.not-first {
  margin-top: 10px;
}