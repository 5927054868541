.home {
  min-height: 100vh;
  position: relative;
}

.home-text {
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 50px;
  position: absolute;
  margin: 0;
}
@media all and (max-width: 479px) { .home-text { padding-left: 10px; } }

.first {
  font-size: 5em;
  font-weight: 600;
}
@media all and (max-width: 479px) { .first { font-size: 4em; } }

.second {
  font-size: 3em;
}
@media all and (max-width: 479px) { .second { font-size: 2em; } }

.name {
  font-size: 5em;
  font-weight: 600;
}
@media all and (max-width: 479px) { .name { font-size: 4em; } }

.about {
  font-size: 1.5em;
  font-style: italic;
}
@media all and (max-width: 479px) { .about { font-size: 1em; } }

.chevron {
  width: 20px;
  height: 20px;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  will-change: transform;
  transform: rotate(135deg);
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  left: 50%;
}
