/* font */
@font-face {
  font-family: dense-regular;
  src: url(/static/media/Dense-Regular.87ae1e61.otf)
}

/*** Default Font styles ***/
h1 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em;
  font-weight: 700;
  font-size: 50px;
  font-style: normal;
  margin-bottom: 0;
  text-transform: uppercase;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.3em;
  font-weight: 700;
  font-size: 35px;
  font-style: normal;
  margin-bottom: 0;
  text-transform: uppercase;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em;
  font-weight: 500;
  font-size: 28px;
  font-style: normal;
  margin-bottom: 0;
  text-transform: uppercase;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em;
  font-weight: 500;
  font-size: 25px;
  font-style: normal;
  margin-bottom: 0;
}

p {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.3em;
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
}

/* h1, h2, h3, h4, p {-webkit-transition: font-size .5s ease-in-out;-moz-transition: font-size .5s ease-in-out;-o-transition: font-size .5s ease-in-out;transition: font-size .5s ease-in-out;} */

/*** Responsive Styles Large Desktop And Above ***/
@media all and (min-width: 1405px) {
  h1 {
    line-height: 1.2em;
    font-size: 50px;
    margin-bottom: 0;
  }

  h2 {
    line-height: 1.3em;
    font-size: 35px;
    margin-bottom: 0;
  }

  h3 {
    line-height: 1.2em;
    font-size: 28px;
    margin-bottom: 0;
  }

  h4 {
    line-height: 1.2em;
    font-size: 25px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.4em;
    font-size: 20px;
    margin-top: 10px;
  }
}

/*** Responsive Styles Standard Desktop Only ***/
@media all and (min-width: 1100px) and (max-width: 1405px) {
  h1 {
    line-height: 1.2em;
    font-size: 45px;
    margin-bottom: 0;
  }

  h2 {
    line-height: 1.3em;
    font-size: 3px;
    margin-bottom: 0;
  }

  h3 {
    line-height: 1.2em;
    font-size: 28px;
    margin-bottom: 0;
  }

  h4 {
    line-height: 1.2em;
    font-size: 23px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.3em;
    font-size: 20px;
    margin-top: 10px;
  }
}

/*** Responsive Styles Tablet And Below ***/
@media all and (max-width: 980px) {
  h1 {
    line-height: 1.2em;
    font-size: 38px;
    margin-bottom: 0;
  }

  h2 {
    line-height: 1.3em;
    font-size: 28px;
    margin-bottom: 0;
  }

  h3 {
    line-height: 1.2em;
    font-size: 24px;
    margin-bottom: 0;
  }

  h4 {
    line-height: 1.2em;
    font-size: 22px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.2em;
    font-size: 16px;
    margin-top: 10px;
  }
}

/*** Responsive Styles Tablet Only ***/
@media all and (min-width: 768px) and (max-width: 980px) {
  h1 {
    line-height: 1.2em;
    font-size: 36px;
    margin-bottom: 0;
  }

  h2 {
    line-height: 1.3em;
    font-size: 26px;
    margin-bottom: 0;
  }

  h3 {
    line-height: 1.2em;
    font-size: 22px;
    margin-bottom: 0;
  }

  h4 {
    line-height: 1.2em;
    font-size: 20px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.4em;
    font-size: 16px;
    margin-top: 10px;
  }
}

/*** Responsive Styles Smartphone Only ***/
@media all and (max-width: 767px) {
  h1 {
    line-height: 1.2em;
    font-size: 30px;
    margin-bottom: 0;
  }

  h2 {
    line-height: 1.3em;
    font-size: 24px;
    margin-bottom: 0;
  }

  h3 {
    line-height: 1.2em;
    font-size: 20px;
    margin-bottom: 0;
  }

  h4 {
    line-height: 1.2em;
    font-size: 18px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.4em;
    font-size: 14px;
    margin-top: 10px;
  }
}

/*** Responsive Styles Smartphone Portrait ***/
@media all and (max-width: 479px) {
  h1 {
    line-height: 1.2em;
    font-size: 28px;
    margin-bottom: 0;
  }

  h2 {
    line-height: 1.3em;
    font-size: 23px;
    margin-bottom: 0;
  }

  h3 {
    line-height: 1.2em;
    font-size: 19px;
    margin-bottom: 0;
  }

  h4 {
    line-height: 1.2em;
    font-size: 17px;
    margin-bottom: 0;
  }

  p {
    line-height: 1.4em;
    font-size: 14px;
    margin-top: 10px;
  }
}

body {
  overflow-x: hidden;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

a {
  text-decoration: none;
  /* transition: all 500ms ease-in-out; */
}
.home {
  min-height: 100vh;
  position: relative;
}

.home-text {
  text-align: left;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-left: 50px;
  position: absolute;
  margin: 0;
}
@media all and (max-width: 479px) { .home-text { padding-left: 10px; } }

.first {
  font-size: 5em;
  font-weight: 600;
}
@media all and (max-width: 479px) { .first { font-size: 4em; } }

.second {
  font-size: 3em;
}
@media all and (max-width: 479px) { .second { font-size: 2em; } }

.name {
  font-size: 5em;
  font-weight: 600;
}
@media all and (max-width: 479px) { .name { font-size: 4em; } }

.about {
  font-size: 1.5em;
  font-style: italic;
}
@media all and (max-width: 479px) { .about { font-size: 1em; } }

.chevron {
  width: 20px;
  height: 20px;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  will-change: transform;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  left: 50%;
}

.work {
  position: relative;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media all and (max-width: 479px) {
  .work {
    width: 100vw;
  }
}

.work h1,
h2 {
  text-align: center;
}

.work h2 {
  padding-bottom: 20px;
}

.work h1 {
  padding-bottom: 20px;
}

.work h3 {
  font-weight: 600;
}

.work .text {
  padding: 40px 0 0 40px;
}

@media all and (max-width: 479px) {
  .work .text {
    padding: 0px 0px 0 40px;
  }
}

.work p {
  width: 420px;
  display: block;
}

@media all and (max-width: 479px) {
  .work p {
    max-width: 330px;
  }
}

.work img {
  width: 470px;
  height: 300px;
}

@media all and (max-width: 479px) {
  .work img {
    width: 380px;
    height: 230px;
  }
}

.row .gutter {
  margin-left: 20;
  margin-right: 20;
}

.row .gutter [class*='col-']:not(:first-child),
.row .gutter [class*='col-']:not(:last-child) {
  padding-right: 0;
  padding-left: 0;
}

.work .work-btn button {
  padding: 0 30px;
  color: #000000;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #000000;
  cursor: pointer;
  top: 0;
  right: 0;
}

.work .work-btn button:hover {
  background-color: #000000;
  color: #FFFFFF;
}
.projects {
  width: 85%;
  margin-top: 20px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media all and (max-width: 575px) {
  .projects {
    left: 54%;
  }
}

.container h1 {
  padding: 50px 0 25px 0;
  font-weight: bolder;
}

.container h1,
h2 {
  text-align: center;
}

img {
  width: 25px;
  height: 25px;
}

.projects p {
  font-size: 14px;
}

.projects-2 p {
  font-size: 14px;
}

.project-box {
  border: 1px solid #e1e4e8;
  border-radius: 3px;
  padding: 18px;
}

.padding {
  margin: 0 20px 0 20px;
}

@media all and (max-width: 575px) {
  .padding {
    margin: 20px 0 20px 0;
  }
}

.dot-yellow {
  height: 15px;
  width: 15px;
  background-color: #F1E05A;
  border-radius: 50%;
  float: left;
  padding: 0;
  margin-right: 5px;
}

.dot-orange {
  height: 15px;
  width: 15px;
  background-color: #FFAB44;
  border-radius: 50%;
  float: left;
  padding: 0;
  margin-right: 5px;
}

.dot-brown {
  height: 15px;
  width: 15px;
  background-color: #B07218;
  border-radius: 50%;
  float: left;
  padding: 0;
  margin-right: 5px;
}

.language {
  padding-left: 10px;
  margin-top: 0px;
}
.form { width: 135%; }
@media all and (max-width: 767px) { .form { width: 100% } }

.form .contact-btn {
  text-align: center;
}

.form .contact-btn button {
  padding: 0 30px;
  color: #000000;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #000000;
  cursor: pointer;
  top: 0;
  right: 0;
}

.form .contact-btn button:hover {
  background-color: #000000;
  color: #FFFFFF;
}
.footer {
  padding: 50px 0 0 10px;
}

.blog { text-align: left; }

.content {
  max-width: 50%;
  margin: 40px auto;
  text-align: left;
}

.date { font-size: 15px; }

.blog-title { font-size: 28px; }


.blogs { opacity: .6; }

.blogs:hover { opacity: 1; }

.logo {
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .logo img {
    height: 40px;
    width: 60px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .logo img {
    height: 35px;
    width: 50px;
  }
}

.blog-link { 
  opacity: .6;
  color: black; 
}

.blog-link:hover { 
  opacity: 1;
  color: black 
}

.not-first {
  margin-top: 10px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25% 1;
  max-width: 25%;
  padding: 0 4px;
}

.column .portrait {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: 20%;
}

.column .landscape {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: 9%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50% 1;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100% 1;
    max-width: 100%;
  }
}

.logo {
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

.quote {
  font-family: 'Courier New', Courier, monospace;
  font-size: 30px;
  text-align: center;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .logo img {
    height: 40px;
    width: 60px;
  }
  .quote {
    font-size: 20px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .logo img {
    height: 35px;
    width: 50px;
  }
  .quote {
    font-size: 15px;
  }
}
.blog1 {
  max-width: 60%;
  margin: 40px auto;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .blog1 { max-width: 75%; }
}

.blog1 h1 {
  font-size: 28px;
}

.date { font-size: 15px; }

.step {
  font-weight: bold;
  font-size: 25px;
}

.logo {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .logo img {
    height: 40px;
    width: 60px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .logo img {
    height: 35px;
    width: 50px;
  }
}

.code-box {
  background-color: #09192F;
  color: #65FFDA;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.thanks {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}

.blog2 {
  max-width: 60%;
  margin: 40px auto;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .blog2 {
    max-width: 75%;
  }
}

.blog2 h1 {
  font-size: 28px;
}

.date {
  font-size: 15px;
}

.bold {
  font-weight: bold;
  font-size: 25px;
}

.logo {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .logo img {
    height: 40px;
    width: 60px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .logo img {
    height: 35px;
    width: 50px;
  }
}

.code-box {
  background-color: #09192F;
  color: #65FFDA;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.thanks {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}
.blog3 {
  max-width: 60%;
  margin: 40px auto;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .blog3 {
    max-width: 75%;
  }
}

.blog3 h1 {
  font-size: 28px;
}

.date {
  font-size: 15px;
}

.bold {
  font-weight: bold;
  font-size: 25px;
}

.sub-bold {
  font-weight: bold;
}

.logo {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .logo img {
    height: 40px;
    width: 60px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .logo img {
    height: 35px;
    width: 50px;
  }
}

.code-box {
  background-color: #09192F;
  color: #65FFDA;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.thanks {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}
