.form { width: 135%; }
@media all and (max-width: 767px) { .form { width: 100% } }

.form .contact-btn {
  text-align: center;
}

.form .contact-btn button {
  padding: 0 30px;
  color: #000000;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #000000;
  cursor: pointer;
  top: 0;
  right: 0;
}

.form .contact-btn button:hover {
  background-color: #000000;
  color: #FFFFFF;
}