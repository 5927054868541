.blog1 {
  max-width: 60%;
  margin: 40px auto;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .blog1 { max-width: 75%; }
}

.blog1 h1 {
  font-size: 28px;
}

.date { font-size: 15px; }

.step {
  font-weight: bold;
  font-size: 25px;
}

.logo {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

.logo img {
  height: 60px;
  width: 80px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .logo {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .logo img {
    height: 40px;
    width: 60px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .logo img {
    height: 35px;
    width: 50px;
  }
}

.code-box {
  background-color: #09192F;
  color: #65FFDA;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.thanks {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}
