.work {
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

@media all and (max-width: 479px) {
  .work {
    width: 100vw;
  }
}

.work h1,
h2 {
  text-align: center;
}

.work h2 {
  padding-bottom: 20px;
}

.work h1 {
  padding-bottom: 20px;
}

.work h3 {
  font-weight: 600;
}

.work .text {
  padding: 40px 0 0 40px;
}

@media all and (max-width: 479px) {
  .work .text {
    padding: 0px 0px 0 40px;
  }
}

.work p {
  width: 420px;
  display: block;
}

@media all and (max-width: 479px) {
  .work p {
    max-width: 330px;
  }
}

.work img {
  width: 470px;
  height: 300px;
}

@media all and (max-width: 479px) {
  .work img {
    width: 380px;
    height: 230px;
  }
}

.row .gutter {
  margin-left: 20;
  margin-right: 20;
}

.row .gutter [class*='col-']:not(:first-child),
.row .gutter [class*='col-']:not(:last-child) {
  padding-right: 0;
  padding-left: 0;
}

.work .work-btn button {
  padding: 0 30px;
  color: #000000;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #000000;
  cursor: pointer;
  top: 0;
  right: 0;
}

.work .work-btn button:hover {
  background-color: #000000;
  color: #FFFFFF;
}